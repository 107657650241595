import React, { useState, useEffect } from "react";
import { DatePicker, Table, Tag, notification,Typography } from "antd";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Home from "../Home";
import { POSTAPI } from "../../components/Vendor/AllApi";
import appConfig from "../../helpers/appConfig";
import { Card, Row, Col, Button ,Modal} from "react-bootstrap";
import axios from "axios";

const { Title, Text } = Typography;

const PaymentList = (props) => {
  const [dailyData, setDailyData] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onlineDate, setOnlineDate] = useState("");
  const [singleBill, setSingleBill] = useState(false);
  const [page, setPage] = useState("list");
  const [singleBillData, setSingleBillData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [failureReasons, setFailureReasons] = useState([]);
  const [retryCounts, setRetryCounts] = useState({}); // Track retries per order
  const bid = localStorage.getItem("bid");

  useEffect(() => {
    console.log("====single bill data===", singleBillData);
  }, [singleBillData]);

  const getDailyRecord = async (data) => {
    try {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/employee/arkiv/adminPayments`,
        data
      );
      setDailyData(resData.data);
      setLoading(false);
      console.log("=======", resData.data);
    } catch (error) {
      console.log(error);
    }
  };

  const printbill = () => {
    window.print();
  };

  useEffect(() => {
    setLoading(true);
    const date = onlineDate ? onlineDate : moment().date()._d;
    filterData(date);
  }, []);

  const filterData = (date) => {
    setOnlineDate(date);
    const startDate = moment(date);
    const endDate = moment(date).add(1, "days");
    let postData = {
      startDate: startDate,
      branchId: bid,
      endDate: endDate,
    };
    console.log("======data=====", postData);
    getDailyRecord(postData);
  };

  const showOrder = (id) => {
    setSingleBill(true);
    let filteredData = dailyData.filter((item) => {
      return item._id == id;
    });
    setSingleBillData(filteredData[0]);
  };

  const goBackMainPage = () => {
    props.history.push("/arkiv");
  };

  const ReportSummaryData = [
    {
      key: "-1",
      render: () => "test",
    },
  ];

  let totalBillPrice = 0;
  dailyData.map((e) => {
    totalBillPrice += e.totalPrice;
  });

  console.log("total price ====", totalBillPrice);

  const handleRetryPayment = async (data) => {
    if (!data._id || !bid) {
      notification.error({
        message: "Missing Data",
        description: "Required payment details are missing. Please try again.",
      });
      return;
    }

    setLoading2(true);
    try {
      const paymentType =
        Number(data.amount) > 0 ? "OppgjørPayment" : "CardVerification";

      const payload = {
        branchId: bid,
        orderId: data._id,
        paymentType: paymentType,
        totalPrice: Number(data.amount), // Ensure it's a number
      };

      console.log("Retry Payment Payload:", payload);

      const response = await axios.post(
        `${appConfig.endpoint}/api/v1/admin/autoPay/createToken`,
        payload
      );

      if (response.status === 200 && response.data.data.url) {
        window.location.replace(response.data.data.url);
      } else {
        notification.error({
          message: "Payment Error",
          description: "Payment URL not received. Please try again.",
        });
      }
    } catch (error) {
      console.error("Payment Error:", error);
      notification.error({
        message: "Payment Failed",
        description: "Payment failed. Please try again later.",
      });
    } finally {
      setLoading2(false);
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${appConfig.endpoint}/api/v1/admin/autoPay/createToken`,
        {
          branchId: bid,
          paymentType: "CardVerification",
          amount: Number(0), // Convert to number before sending
        }
      );

      if (response.status === 200 && response.data.data.url) {
        window.location.replace(response.data.data.url);
      } else {
        // Use notification.error for any error or fallback
        notification.error({
          message: "Payment Error",
          description: "Payment URL not received. Please try again.",
        });
      }
    } catch (error) {
      console.error("Payment Error:", error);
      notification.error({
        message: "Payment Failed",
        description: "Payment failed. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleRetryRegularPayment = async (data) => {
    if (!data._id || !bid) {
      notification.error({
        message: "Missing Data",
        description: "Required payment details are missing. Please try again.",
      });
      return;
    }
  
    setLoading(true);
    try {
      const paymentType =
        Number(data.amount) > 0 ? "OppgjørPayment" : "CardVerification";
  
      const payload = {
        branchId: bid,
        orderId: data._id,
        paymentType: paymentType,
        amount: Number(data.amount),
      };
  
      console.log("Retry Payment Payload:", payload);
  
      const response = await axios.post(
        `${appConfig.endpoint}/api/v1/admin/autoPay/manualPayment`,
        payload
      );
  
      // Check if response structure is valid
      if (!response.data || !response.data.data || response.data.data.length === 0) {
        return; // Just return without notification
      }
  
      const responseData = response.data.data[0];
  
      // **Fix: Ensure count is correctly checked**
      if (responseData.count >= 3 && responseData.status !== "Payment processed") {
        setFailureReasons([
          "Expired card",
          "Token is expired",
          "Insufficient funds in the card",
        ]);
        setIsModalVisible(true);
        return; // Stop further execution
      }
  
      if (responseData.paymentStatus === "AUTHORIZED") {
        notification.success({ message: "Payment processed successfully" });
  
        // Refresh the payments list
        await getDailyRecord({
          startDate: moment(onlineDate),
          branchId: bid,
          endDate: moment(onlineDate).add(1, "days"),
        });
      }
    } catch (error) {
      console.error("Payment Error:", error);
      setFailureReasons([
        "Expired card",
        "Token is expired",
        "Insufficient funds in the card",
      ]);
      setIsModalVisible(true);
      return; // Stop further execution
      notification.error({
        message: "Payment Failed",
        description: "Payment failed. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };
  

  

  const ReportSummary = () => [
    {
      title: "Date",
      render: (text, record) =>
        `${moment(record.createdAt).format("DD/MM/YYYY")} - ${new Date(
          record.createdAt
        ).toLocaleTimeString()}`,
      width: "15%",
    },
    {
      title: "Payment Type",
      width: "15%",
      render: (text, record) => {
        return <b>For {record.paymentType}</b>;
      },
    },
    // {
    //   title: "Recurrence Token",
    //   width: "20%",
    //   dataIndex: 'recurrence_token',
    // },
    {
      title: "Amount",
      width: "10%",
      render: (text, record) => {
        return <b>Kr. {record.amount}</b>;
      },
      key: "amount",
    },
    {
      title: "Status",
      width: "15%",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "FAILED" ? "red" : "green"}>{status}</Tag>
      ),
    },
    {
      title: "Retry",
      width: "5%",
      render: (text, record) =>
        record.status === "FAILED" ? (
          // record.paymentType == "CardVerification" &&
          // record.status === "FAILED" ? (
          <center>
            <Button
              onClick={() => {
                if (record.paymentType === "CardVerification") {
                  handleRetryPayment(record);
                } else {
                  handleRetryRegularPayment(record);
                }
              }}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </Button>
          </center>
        ) : (
          ""
        ),
    },
  ];

  return (
    <div>
      <Home>
        <>
          <Row>
            <Col>
              <div className="report-container">
                <span className="item" style={{ width: "60%" }}>
                  <span id="less-visible">Admin Payments </span>
                </span>
                <span id="less-visible">
                  <Button
                    onClick={() => goBackMainPage()}
                    className="ant-btn back-btn"
                  >
                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                  </Button>
                </span>
              </div>
              <Row
                className="p-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Col span={12}>
                  <DatePicker
                    onChange={(date, dateString) => filterData(date)}
                    format="YYYY-MM-DD"
                    placeholder="Select day"
                  />
                </Col>
                <Col
                  span={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <button
                    className="button is-primary btn-ab px-5 rounded py-2"
                    onClick={handlePayment}
                    loading={loading2}
                  >
                    {loading2 ? "Processing..." : "Add Your Card"}
                  </button>
                </Col>
              </Row>

              <div className="p-4">
                <Table
                  id="tablePrint"
                  loading={loading}
                  columns={ReportSummary()}
                  dataSource={dailyData}
                  summaryData={ReportSummaryData}
                  summaryColumns={ReportSummary(totalBillPrice)}
                  striped
                  bordered
                  rowKey={props.rowKey}
                />
              </div>
            </Col>
          </Row>
        </>
      </Home>
      <>
      <Modal
      show={isModalVisible}
      onHide={() => setIsModalVisible(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-danger">Payment Failed</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <p className="text-muted">
          Payment failed due to one of the following reasons:
        </p>
        <div>
          {failureReasons.map((reason, index) => (
            <p className="ml-3"> - {reason}</p>
          ))}
        </div>
        <p className="text-muted">Please add your card again.</p>
      </Modal.Body>
    </Modal>
  </>
    </div>
  );
};
export default PaymentList;

// import React from 'react'
// import Home from '../Home';
// import { NavLink } from "react-router-dom";
// import { Row, Col, Card } from "react-bootstrap";
// import {  Button  } from 'antd'; // <-- Import notification
// const index = (props) => {

//   const goBackMainPage = () => {
//     props.history.push("/arkiv");
//   };
//   return (
//     <Home>
//       {/* <div className="report-container text-center"
//         style={{ marginBottom: "0px" }}
//       >
//         <span className="item ">Payment Section</span>
//       </div> */}
//       <div className="report-container">
//             <span className="item" style={{ width: "60%" }}>
//               <span id="less-visible">Arkiv :  </span>
//               Admin Payment Section
//             </span>

//           <span id="less-visible">
//               <Button
//                 onClick={() => goBackMainPage()}
//                 className="ant-btn back-btn"
//               >
//                 <i className="fa fa-arrow-circle-left" aria-hidden="true" />
//               </Button>
//             </span>
//           </div>
//       <Card>
//         <Card.Body>
//           <Row  justify="space-evenly" className="m-b-1">
//             <Col span={6} className="text-left" style={{ marginTop: "15px", marginLeft: "40px" }}>
//               <NavLink
//                 to="/cardVerification"
//                 type="primary"
//                   className="btn-link-ok test3"
//                 style={{ width: "100px", padding: "15px", borderRadius:"5px" }}
//               >
//                 <span>CARD VERIFICATIONS</span>
//               </NavLink>

//             </Col>
//             <Col span={6} className="text-left" style={{ marginTop: "15px" }}>
//               <NavLink
//                 to="/adminPayments"
//                 type="primary"
//                   className="btn-link-ok test3"
//                 style={{ width: "100px", padding: "15px", borderRadius:"5px" }}
//               >
//               PAYMENT DETAILS
//               </NavLink>

//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>

//     </Home>
//   )
// }

// export default index
