import React, { useState, useEffect } from "react";
import {  Row, Col, DatePicker, Button } from "antd";
import { Card } from "react-bootstrap";
import { POSTAPI } from "../../Vendor/AllApi";
import appConfig from "../../../helpers/appConfig";
import { Chart } from "react-google-charts";
import moment from "moment";
import { Typography } from 'antd';
import "./indec.css";
import { Empty } from "antd";
const bid = localStorage.getItem("bid");
const token = localStorage.getItem("token");
const { MonthPicker, WeekPicker } = DatePicker;
const { Title } = Typography;

const PaymentGraph = () => {
  const [data, setData] = useState({});
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");
  const [currDate, setCurrDate] = useState("");
  const [graphDate, setGraphDate] = useState("");

  useEffect(()=>{
    let date = new Date();
    let startDate = moment(date).startOf("month").add(1, "days")._d;
    let endDate =moment(date).endOf("month").add(1, "days")._d;
    let branchId = bid;
    let data = {};
    data.startDate = startDate;
    data.endDate = endDate;
    data.branchId = bid;

    paymentModeData(data);

    console.log("intial data =====", startDate , endDate , branchId);

  },[])

  const paymentModeData = async (postdata) => {
    const headers = {
      "x-auth-token": token,
    };
    try {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/employee/invoice/IncomeDashboard`,
        postdata,
        {
          headers: headers,
        }
      );
      if (Object.keys(resData.data).length > 0) {
        setData(resData.data);
        setGraphDate(resData.BeDate);
        console.log(Object.keys(resData.data).length);
      }else{
        setData({});
        setGraphDate("");
      }
    } catch (error) {
      setData({});
      console.log(error);
      setGraphDate('');
    }
  };

  const filterData = (date, type) => {
    let postdata = {};
    let day = "";
    day = type;
    // console.log("type==========" , day )

    switch (type) {
      case "m":
        postdata = {
          startDate: moment(date).startOf("month").add(1, "days"),
          endDate: moment(date).endOf("month").add(1, "days"),
          branchId: bid,
        };
        break;
      case "w":
        postdata = {
          startDate: moment(date).startOf("week").add(2, "days"),
          endDate: moment(date).endOf("week").add(2, "days"),
          branchId: bid,
        };
        break;
      case "d":
        postdata = {
          startDate: moment(date),
          endDate: moment(date).add(1, "days"),
          branchId: bid,
        };
    }
    paymentModeData(postdata);
  };

  const ddd = [
    ["Payment Mode", "Amount"],
    [`Kort - Kr. ${Number(data.cardTotal).toFixed(2)}`, data.cardTotal],
    [`Cash - Kr. ${Number(data.cashTotal).toFixed(2)}`, data.cashTotal],
    [`Kreditt - Kr.  ${Number(data.creditTotal).toFixed(2)}`, data.creditTotal],
    [`Vipps - Kr. ${Number(data.vippsTotal).toFixed(2)}`, data.vippsTotal],
  ];
  const options = {
    is3D: false,
    height: 400,
    width: 560,
    chartArea: { left: 0, top: 20, width: "100%", height: "100%" },
    animation: {
      startup: true, // Enable animation when the chart is first rendered
      duration: 3000, // Animation duration in milliseconds
      easing: 'out', // Animation easing function
    },
  };

  return (
    <>
    <Card className='mb-4'>
      <Card.Header className="h4">The Percentage Distribution Of Income Received</Card.Header>
      </Card>
      <div className="paymentgraph d-flex m-3 justify-content-evenly">
        <DatePicker onChange={(e) => filterData(e, "d")} />
        <WeekPicker
          onChange={(e) => filterData(e, "w")}
          placeholder="Select week"
        />
        <MonthPicker
          placeholder="Select Month"
          onChange={(e) => filterData(e, "m")}
        />
        <div
          className="justify-content-end
            "
        ></div>
      </div>
      <Row className="graph-display" gutter={16}>
        <Col style={{ paddingTop:"50px"}}>
          { Object.keys(data).length == 0 ? (
                <div className="d-flex justify-content-center pt-5">
                  <Empty />
                </div>
              ) : (
              <div >
              <div className="d-flex">
              <span className="heading2"> {moment(graphDate.startDate).format('MMM DD, YYYY')} 04:00 am to {moment(graphDate.endDate).format('MMM DD, YYYY')}  03:59 am</span>
              <span className="heading2 pl-5">Total : Kr.{Number(data.totalAmount).toFixed(2)} </span>
              </div>
              <div style={{paddingLeft:"200px"}}>
              <Chart
                  chartType="PieChart"
                  data={ddd}
                  options={options}
                  width={"100%"}
                  height={"300px"}
                /> </div>
              </div>
              )}
        </Col>
      </Row>
    </>
  );
};

export default PaymentGraph;
