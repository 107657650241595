import React ,{useEffect,useState}from "react";
import { NavLink } from "react-router-dom";
import { Card, Col, Row, Descriptions, Divider ,Table,Icon} from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { Container } from "react-bootstrap";
import { GETAllAPI, POSTAPI } from "../Vendor/AllApi";
import appConfig from "../../helpers/appConfig";
import Home from "../Home"
import { Spin } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";

const AdminPaymentSucess = (props) => {

const [resData,setResData]=useState(null);
const[paymentFailed , setPaymentFailed]= useState(false);
  const paramUrl = window.location.href;
  const encUrl = new URL(paramUrl);
  const params = new URLSearchParams(encUrl.search);
  let merchant_reference =  params.get("merchant_reference");
  let transaction_id =  params.get("transaction_id");
  console.log("merchant_reference", merchant_reference);
  console.log("transaction_id", transaction_id);
  console.log("params",  params);

  const checkPayment = async (data) => {
    let res = await POSTAPI(
      `${appConfig.endpoint}/api/v1/admin/autoPay/paymentSuccess`,
      data
    );
    if (res && res.status == 200) {
      console.log("res data createOrder ", res.data);
      setResData(res.data)
    } else {
      setPaymentFailed(true);
      console.log("res data payment check error=========== ", resData);
    }
  };
  
  useEffect(() => {
    let data = {
      id: merchant_reference,
      transaction_id,
    };
    checkPayment(data);
  }, []);
  
  if(resData){
    console.log("resData", resData)
  const branchInfo = JSON.parse(localStorage.getItem("branchInfo"));
  const totalPrice = resData.totalPrice;


  return (

    <Home>
    <div className="d-flex justify-content-center align-items-center">
      <Card className="p-5 mt-5 text-center shadow-lg" style={{ maxWidth: "600px" , borderRadius:"10px"}}>
        <Row className="pb-3 d-flex justify-content-center align-items-center">
          <AiOutlineCheckCircle className="tickCheck" size={90} color="green" />
        </Row>
        <h3 className="heading-h3 mb-3">Congratulations</h3>
        {
          resData.paymentType === "OppgjørPayment" ? 
          <h3 className="heading-h3 mb-3">Your payment completed successfully!</h3> :
          <h3 className="heading-h3 mb-3">Your card verified successfully!</h3>
        }
       
        <p style={{ fontSize: "14px" }}>
        </p>
        <Row className="pt-4 d-flex justify-content-center">
          <NavLink
            to="/superAdminPayments"
            // className="btn btn-primary"
             className="btn-link-ok test3"
            style={{
              width: "150px",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            Back to Admin Payments Section
          </NavLink>
        </Row>
      </Card>
    </div>
  </Home>
  
  );
  }
  
if(resData==null && !paymentFailed){
  return <Home>
    <Row>
  <Col
    span={24}
    style={{
      textAlign: "center",
      padding: "90px",
      color: "red",
      fontSize: "20px"
    }}
  >
    <Spin
      // indicator={
      //   <LoadingOutlined style={{ fontSize: 40, color: "red" }} spin />
      // }
    />
    <h3 style={{ marginTop: "20px", color: "grey" }}>
      Loading, please wait...........
    </h3>
  </Col>
</Row>
  </Home>
}
if(paymentFailed){
  return  <Home>
    <Row>
            <Col
              span={24}
              style={{
                textAlign: "center",
                padding: "50px",
                color: "white"
              }}
            >
            <div className="loading bg-white">
            <Icon type="close-circle" style={{ color: 'red', fontSize:"60px" }} />
            <h2 className="pt-4">Oops!! Something went wrong</h2>
              <h3>Your payment failed...</h3>
              {/* <h4>Please try again</h4> */}
              <Row className="pt-5 d-flex align-items-center">
            {" "}
            <NavLink to="/cardVerification" className="payCancel-btn">
              Try Again
            </NavLink>
          </Row>
              </div>
            </Col>
          </Row>
  </Home>
}
};

export default AdminPaymentSucess;

// // PaymentSuccess.js
// import React from 'react';
// import { Card, Typography } from 'antd';
// import Home from '../Home';

// const { Title, Paragraph } = Typography;

// const AdminPaymentSucess = (props) => {

//   const paramUrl = window.location.href;
//   // console.log("paramUrl", paramUrl);
//   const encUrl = new URL(paramUrl);
//   const params = new URLSearchParams(encUrl.search);
//   let merchant_reference =  params.get("merchant_reference");
//   let transaction_id =  params.get("transaction_id");
//   console.log("merchant_reference", merchant_reference);
//   console.log("transaction_id", transaction_id);


//   const goBackMainPage = () => {
//     props.history.push("/superAdminPayments");
//   }; 

//   return (
//   <Home>
//       <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
//       <Card style={{ width: 400, textAlign: 'center' }}>
//         <Title level={2}>Payment Successful!</Title>
//         <Paragraph>Your transaction has been processed successfully.</Paragraph>
//       </Card>
//     </div>
//   </Home>
//   );
// };

// export default AdminPaymentSucess;


