// import React, { useState, useEffect } from 'react';
// import Home from "../Home";
// import { Card, Form, Input, Button, message, Row } from 'antd';

// const CardDetails = (props) => {
//   const [cardNumber, setCardNumber] = useState('');
//   const [cardHolderName, setCardHolderName] = useState('');
//   const [expiry, setExpiry] = useState('');
//   const [cvv, setCvv] = useState('');

//   useEffect(() => {
//     // Reset fields when the component mounts
//     setCardNumber('');
//     setCardHolderName('');
//     setExpiry('');
//     setCvv('');
//   }, []);

//   const goBackMainPage = () => {
//     props.history.push("/superAdminPayments");
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     if (!cardNumber || !cardHolderName || !expiry || !cvv) {
//       message.error('Please fill in all fields.');
//       return;
//     }else{
//       console.log("all the values", cardNumber ,cardHolderName ,expiry , cvv  )
//     }
//   };

//   return (
//     <Home>
//       <div className="p-2"> 
//         <div className="report-container"> 
//           <span className="item" style={{ width: "60%" }}>Card Details</span>
//           <span id="less-visible">
//             <Button onClick={goBackMainPage} className="ant-btn back-btn">
//               <i className="fa fa-arrow-circle-left" aria-hidden="true" />
//             </Button>
//           </span>
//         </div>
//         <Card title="Enter Card Details" className="p-4">
//         <Form 
//   onSubmit={handleSubmit} 
//   className="ant-form" 
//   autoComplete="off"
// >
//   {/* Hidden fields to "trick" autofill */}
//   <Input 
//     type="text" 
//     name="hiddenUsername" 
//     style={{ display: 'none' }} 
//     autoComplete="username"
//   />
//   <Input 
//     type="password" 
//     name="hiddenPassword" 
//     style={{ display: 'none' }} 
//     autoComplete="new-password"
//   />

//   <div className="row">
//     <div className="col-md-6">
//       <Form.Item label="Card Number">
//         <Input
//           type="text"
//           name="fakeCardNumber" 
//           placeholder="Enter card number"
//           value={cardNumber}
//           onChange={(e) => setCardNumber(e.target.value)}
//           autoComplete="off"
//         />
//       </Form.Item>
//     </div>
//     <div className="col-md-6">
//       <Form.Item label="Card Holder Name">
//         <Input
//           type="text"
//           name="fakeCardHolderName"
//           placeholder="Enter card holder name"
//           value={cardHolderName}
//           onChange={(e) => setCardHolderName(e.target.value)}
//           autoComplete="off"
//         />
//       </Form.Item>
//     </div>
//     <div className="col-md-6">
//       <Form.Item label="Expiry (MM/YY)">
//         <Input
//           type="text"
//           name="fakeExpiry"
//           placeholder="MM/YY"
//           value={expiry}
//           onChange={(e) => setExpiry(e.target.value)}
//           autoComplete="off"
//         />
//       </Form.Item>
//     </div>
//     <div className="col-md-6">
//       <Form.Item label="CVV">
//         <Input
//           type="password"
//           name="fakeCVV"
//           placeholder="CVV"
//           value={cvv}
//           onChange={(e) => setCvv(e.target.value)}
//           autoComplete="new-password"
//         />
//       </Form.Item>
//     </div>
//   </div>
// </Form>
//           <Row className="p-3">
//               <button
//                 className="button is-primary btn-ab px-5 rounded py-2"
//                 onClick={handleSubmit}
//               >
//                 Update
//               </button>
//             </Row>
//         </Card>
//       </div>
//     </Home>
//   );
// };

// export default CardDetails;

import React, { useState } from 'react';
import axios from 'axios';
import Home from "../Home";
import { Card, Form, Input, Button, Row, notification } from 'antd'; // <-- Import notification
import appConfig from "../../helpers/appConfig";

const CardDetails = (props) => {
  const bid = localStorage.getItem("bid");
    
  console.log("rest id===========",bid)
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('1');

  const goBackMainPage = () => {
    props.history.push("/superAdminPayments");
  };

  const handlePayment = async () => {
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      // Instead of message.error, use notification.error
      notification.error({
        message: 'Invalid Amount',
        description: 'Please enter a valid amount.',
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${appConfig.endpoint}/api/v1/admin/autoPay/createToken`, {
        branchId: bid,
        paymentType:"CardVerification",
        amount: Number(amount) // Convert to number before sending
      });

      if (response.status === 200 && response.data.data.url) {
        window.location.replace(response.data.data.url);
      } else {
        // Use notification.error for any error or fallback
        notification.error({
          message: 'Payment Error',
          description: 'Payment URL not received. Please try again.',
        });
      }
    } catch (error) {
      console.error("Payment Error:", error);
      notification.error({
        message: 'Payment Failed',
        description: 'Payment failed. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Home>
      <div>
        {/* <div className="p-2">
          <div className="report-container">
            <span className="item" style={{ width: "60%" }}>Payment Section</span>
            <span id="less-visible">
              <Button onClick={goBackMainPage} className="ant-btn back-btn">
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </Button>
            </span>
          </div>
        </div> */}
        <div className="report-container">
            <span className="item" style={{ width: "60%" }}>
              <span id="less-visible">Arkiv :  </span>
              CARD VERIFICATION
            </span>

            <span id="less-visible">
              <Button
                onClick={() => goBackMainPage()}
                className="ant-btn back-btn"
              >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </Button>
            </span>
          </div>

        {/* Center the card */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh', // Adjust as needed
          }}
        >
          <Card
            style={{
              width: '600px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: "40px"
            }}
          >
            {/* Amount Input Field */}
            <label htmlFor="amount" style={{ color: "black" }}>Add Your Card:</label>
            {/* <Input
              id="amount"
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            /> */}

            <Row className="p-3">
              <button
                // type="primary"
                className="button is-primary btn-ab px-5 rounded py-2"
                onClick={handlePayment}
                loading={loading}
              >
                {loading ? "Processing..." : "Click Here"}
              </button>
            </Row>
          </Card>
        </div>
      </div>
    </Home>
  );
};

export default CardDetails;


