import { PROFILE_SWITCH_BRANCH } from "../../action/types";
const initialState = {
  branch: {},
  currentOrders: 0,
  employee: {},
  logo: ""
};
const switchBranchReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_SWITCH_BRANCH:
      let {
        slug,
        address,
        taxes,
        name,
        accountNumber,
        taxNo,
        isQrAllow,
        additional,
        tdDiscountPermission,
        isTreeDriveAllowed,
        isDriveMyself,
        tdDiscount,
        DriveMyselfPermission,
        driveoutCharge,
        isFindDriver,
        isWebOpperKonto,
        //isVendorAllowed,
        branchType
      } = action.payload.data.branch;
      const btaxes = JSON.stringify(taxes) || [];
      const bEmail = action.payload.data.branch.email;
      localStorage.setItem("taxes", btaxes);
      localStorage.setItem("crm", action.payload.data.employee.crm);
      localStorage.setItem("hrm", action.payload.data.employee.hrm);
      localStorage.setItem(
        "taccountant",
        action.payload.data.employee.taccountant
      );
      localStorage.setItem("stock", action.payload.data.employee.stock);
      localStorage.setItem("invoice", action.payload.data.employee.invoice);
      localStorage.setItem("managecat", action.payload.data.employee.managecat);
      localStorage.setItem(
        "openaccount",
        action.payload.data.employee.openaccount
      );
      localStorage.setItem(
        "openaccountWeb",
        action.payload.data.employee.openaccountWeb
      );
      localStorage.setItem("tableview", action.payload.data.employee.tableview);
      localStorage.setItem("kitchen", action.payload.data.employee.kitchen);
      localStorage.setItem("bar", action.payload.data.employee.bar);
      localStorage.setItem("logo", action.payload.data.logo);
      localStorage.setItem("currentOrders", action.payload.data.currentOrders);
      localStorage.setItem("bid", action.payload.data.branch._id);
      localStorage.setItem("isAutoPayAllowed", action.payload.data.branch.isAutoPayAllowed);
      localStorage.setItem("slug", slug);
      localStorage.setItem("tflamount", action.payload.data.branch.tfl.amount);
      localStorage.setItem("isVendorAllowed", action.payload.data.branch.isVendorAllowed);
      console.log("isVendorALLOWEDD==================" ,action.payload.data.branch.isVendorAllowed)

      localStorage.setItem("tdDiscount", additional.tdDiscountPermission);
      localStorage.setItem("couponPermission", additional.couponPermission);
      localStorage.setItem("vippsPermission", additional.vippsPermission);
      // localStorage.setItem("DriveMyselfPermission", isDriveMyself);
      localStorage.setItem("DriveMyselfPermission", DriveMyselfPermission);
      localStorage.setItem("driveoutCharge",  driveoutCharge);
      localStorage.setItem("isFindDriver",  isFindDriver);
      // localStorage.setItem("kitchenpermission", additional.kitchenpermission);
      // localStorage.setItem("barpermission", action.payload.data.additional.barpermission);
      //
      console.log(isTreeDriveAllowed, "istreeallowed");
      console.log( driveoutCharge, " driveoutCharge switch");

      localStorage.setItem("isTreeDriveAllowed", isTreeDriveAllowed);
      localStorage.setItem("isWebOpperKonto", isWebOpperKonto);
      //localStorage.setItem("isVendorAllowed", isVendorAllowed);
      //console.log("data===================", isVendorAllowed)

      localStorage.setItem("empType", action.payload.data.employee.empType);
      localStorage.setItem(
        "branchInfo",
        JSON.stringify({
          name,
          accountNumber,
          bEmail,
          taxNo,
          address,
          isQrAllow
        })
      );
     localStorage.setItem("branchType",branchType)
      return {
        ...state,
        branch: action.payload.data.branch,
        employee: action.payload.data.employee,
        currentOrders: action.payload.data.currentOrders,
        logo: action.payload.data.logo
      };
    default:
      return state;
  }
};
export default switchBranchReducer;
