import React, { useState, useEffect } from "react";
import { DatePicker, Table , Tag,notification} from "antd";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Home from "../Home";
import { POSTAPI } from "../../components/Vendor/AllApi";
import appConfig from "../../helpers/appConfig";
import { Card, Row, Col, Button } from "react-bootstrap";
import axios from 'axios';
// import { Card, Form, Input, Button, Row, notification } from 'antd'; // <-- Import notification

const PaymentList = (props) => {

  const [dailyData, setDailyData] = useState([])
  const [loading, setLoading] = useState(false);
  const [onlineDate, setOnlineDate] = useState("");
  const [singleBill, setSingleBill] = useState(false);
  const [page, setPage] = useState("list");
  const [singleBillData, setSingleBillData] = useState([]);
  const bid = localStorage.getItem("bid")

  useEffect(() => {
      console.log("====single bill data===", singleBillData)
  }, [singleBillData])

  const getDailyRecord = async (data) => {
    try {
        let resData = await POSTAPI(`${appConfig.endpoint}/api/v1/employee/arkiv/adminPayments`,
            data
        )
        setDailyData(resData.data)
        setLoading(false)
        console.log("=======", resData.data)
    } catch (error) {
        console.log(error)
    }
}

const printbill = () => {
  window.print();
};

useEffect(() => {
  setLoading(true);
  const date = onlineDate ? onlineDate : moment().date()._d;
  filterData(date);
}, [])

const filterData = (date) => {
  setOnlineDate(date)
  const startDate = moment(date);
  const endDate = moment(date).add(1, "days");
  let postData = {
      startDate: startDate,
      branchId: bid,
      endDate: endDate
  }
  console.log("======data=====", postData)
  getDailyRecord(postData)
}

const showOrder = (id) => {
  setSingleBill(true)
  let filteredData = dailyData.filter((item) => {
      return item._id == id
  })
  setSingleBillData(filteredData[0]);
}

const goBackMainPage = () => {
      props.history.push("/superAdminPayments");

};


const ReportSummaryData = [
  {
      key: "-1",
      render: () => "test"
  }
];

let totalBillPrice = 0;
dailyData.map((e) => {
  totalBillPrice += e.totalPrice;
})

console.log("total price ====", totalBillPrice)




const handleRetryPayment = async (data) => {
  if (!data.amount || isNaN(data.amount) || Number(data.amount) <= 0) {
    notification.error({
      message: 'Invalid Amount',
      description: 'Please enter a valid amount.',
    });
    return;
  }

  if (!data._id || !bid) {
    notification.error({
      message: 'Missing Data',
      description: 'Required payment details are missing. Please try again.',
    });
    return;
  }

  setLoading(true);
  try {
    const paymentType = Number(data.amount) > 1 ? "OppgjørPayment" : "CardVerification";

    const payload = {
      branchId: bid,
      orderId: data._id,
      paymentType: paymentType,
      totalPrice: Number(data.amount), // Ensure it's a number
    };

    console.log("Retry Payment Payload:", payload);

    const response = await axios.post(`${appConfig.endpoint}/api/v1/admin/autoPay/createToken`, payload);

    if (response.status === 200 && response.data.data.url) {
      window.location.replace(response.data.data.url);
    } else {
      notification.error({
        message: 'Payment Error',
        description: 'Payment URL not received. Please try again.',
      });
    }
  } catch (error) {
    console.error("Payment Error:", error);
    notification.error({
      message: 'Payment Failed',
      description: 'Payment failed. Please try again later.',
    });
  } finally {
    setLoading(false);
  }
};


  const ReportSummary = () => [
    {
      title: "Date",
      render: (text, record) =>
      `${moment(record.createdAt).format("DD/MM/YYYY")} - ${new Date(record.createdAt).toLocaleTimeString()}`,
      width: "15%"
    },
    {
        title: "Payment Type",
      width: "15%",
      render: (text, record) => {
        return <b>For {record.paymentType}</b>;
      }
    },
    // {
    //   title: "Recurrence Token",
    //   width: "20%",
    //   dataIndex: 'recurrence_token',
    // },
    {
      title: "Amount",
      width: "10%",
      render: (text, record) => {
        return <b>Kr. {record.amount }</b>;
      },
      key: 'amount',
    },
    {
      title: "Status",
      width: "15%",
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === "FAILED" ? "red" : "green"}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Retry",
      width: "5%",
      render: (text, record) =>
        // record.status === "FAILED" ? (
        record.paymentType == "CardVerification" && record.status === "FAILED" ? (
          <center>
            <Button
              onClick={() => {
                if (record.paymentType === "CardVerification") {
                  handleRetryPayment(record);
                } else {
                  // handleRetryRegularPayment(record);
                }
              }}
              style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px 10px" }}
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </Button>
          </center>
        ) : (
          ""
        ),
    },
  ];



  return (
    <div>
            <Home>
            <>
                                <Row>
                                    <Col>
                                        <div className="report-container">
                                            <span className="item" style={{ width: "60%" }}>
                                                <span id="less-visible">Admin Payments  </span>
                                            </span>
                                            <span id="less-visible">
                                                <Button
                                                    onClick={() => goBackMainPage()}
                                                    className="ant-btn back-btn"
                                                >
                                                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                                                </Button>
                                            </span>
                                        </div>
                                        <div className='d-flex pl-4'>
                                            <div>
                                                <DatePicker
                                                    onChange={(date, dateString) => {
                                                        filterData(date)
                                                    }}
                                                    format="YYYY-MM-DD"
                                                    placeholder="Select day"
                                                />
                                            </div>
                                            {/* <div className=' mb-2'
                                                style={{ paddingLeft: "700px" }}
                                                id="printbtn">
                                                <Button
                                                    onClick={() => printbill()}
                                                    type="primary"
                                                    size="large"
                                                    className="btn-ab  text-color tablePrint"
                                                >
                                                    <i
                                                        className="fa fa-print"
                                                        style={{ fontSize: 20 }}
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            </div> */}
                                        </div>
                                        <div className="p-4">
                                            <Table
                                                id="tablePrint"
                                                loading={loading}
                                                columns={ReportSummary()}
                                                dataSource={dailyData}
                                                summaryData={ReportSummaryData}
                                                summaryColumns={ReportSummary(totalBillPrice)}
                                                striped
                                                bordered
                                                rowKey={props.rowKey}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </>
            </Home>
        </div>
  );
};
export default PaymentList
